import React from 'react'
import ReactGA from 'react-ga';
import Shop from './Shop'
import Apps from './Apps'
import Gallery from './Gallery'
import TopBar from './TopBar'
import Footer from './Footer'
import Focus from './Focus'
import Menu from './Menu'
import Search from './Search'
import AppData from './AppData.js'
import ProductData from './ProductData.js'
import PrivacyPolicy from './PrivacyPolicy'
import NewEntrance from './NewEntrance' 
import '../App.css';
import '../Mobile_App.css'

var pageIntro = ''

export default class Dashboard extends React.Component {

    constructor(props){
      super(props)
      this.state = {
        page:'Entrance',
        content:'Home',
        gallery_grid: null,
        gallery_filtered: false,
        focus: null,
        searchValue: '',
        search_Message: null,
        searchMode: null,
        searchPlaceholder: '...find what?',
        menu: false,
        
      }

      this.pageSelect = this.pageSelect.bind(this)
      this.focusSelect = this.focusSelect.bind(this)
      this.focusOff = this.focusOff.bind(this)
      this.searchPage = this.searchPage.bind(this)
      this.searchReset= this.searchReset.bind(this)
      this.menuOn= this.menuOn.bind(this)
 
    }

    componentDidMount() {

      const trackingId_01 = "UA-118761430-1";
      const trackingId_02 = "G-XS4ZKCWJEC";
      ReactGA.initialize(trackingId_01);
      ReactGA.initialize(trackingId_02);
       
    }
  
    
    pageSelect(pageChoice){
       
      let setGridContent = null
      let setPageContent = null
  
      const context = 'Page: '
      this.track(context,pageChoice)

      if (pageChoice === 'Entrance') {
  
        setGridContent = null
        setPageContent = 'Home'
      }

      if (pageChoice === 'Apps') {
        setGridContent = AppData
        setPageContent = 'Gallery'
      }

      if (pageChoice === 'Shop') {
        setGridContent = ProductData
        setPageContent = 'Gallery'
      }

      if (pageChoice === 'PrivacyPolicy') {
        setGridContent = null
        setPageContent = 'PrivacyPolicy'
      }

      this.setState({
        content: setPageContent,
        page: pageChoice,
        gallery_grid: setGridContent,
        focus: null,
        search_Message: null,
        searchMode: pageChoice,
        searchPlaceholder: '',
        searchValue: null,
        gallery_filtered: false,
        menu: false,

      })

    }

    focusSelect(item){

      const context = 'Product: '
      this.track(context,item)
       
      this.setState({
        focus:item
      })
   
    }

    focusOff(e){

      let focus_id = parseInt(e.target.id)
 
      if (!Number.isInteger(focus_id)){
        this.setState({
          focus:null
        })
      }
      
    }

    searchPage(word){

      if (word) {
         
        const context = 'Search: '
        this.track(context,word)
  
        var filtered_Gallery = []
        var counter = 0
      
        let fixed_word = word.toLowerCase()

        if (word === 'header_vehicles') {
          fixed_word = 'vehicles'
        }

        if (word === 'header_matchpuzzles') {
          fixed_word = 'matching and puzzle apps'
        }

        if (word === 'header_bundles') {
          fixed_word = 'bundles'
        }

        var SearchData = ['']
 
        //Sort-Search apps 
        if (this.state.page === 'Apps') {

          SearchData = AppData

        }

        if (this.state.page === 'Shop') {

          SearchData = ProductData

        }

            //search through terms
            SearchData.forEach((item) => {

              let searchTerms = item.search_Terms
 
              let match = searchTerms.filter(term => term.toLowerCase().includes(fixed_word))
  
              if (match.length > 0) {
 
                filtered_Gallery[counter] = item
                counter += 1
              
              }
                
            })

            var searchedWord = word
            var searchMode = this.state.page

            if (word === 'header_vehicles') {
              searchedWord = 'Trucks and Vehicle Apps'
              searchMode = 'Vehicles'
            }
    
            if (word === 'header_matchpuzzles') {
              searchedWord = 'Matching and Puzzle Apps'
              searchMode = 'MatchPuzzles'
            }
    
            if (word === 'header_bundles') {
              searchedWord = 'App Bundles'
              searchMode = 'Bundles'
            }
 
            if (filtered_Gallery){

              this.setState({

                gallery_grid: filtered_Gallery,
                gallery_filtered: true,
                search_Message: `Showing all results for "${searchedWord}"`,
                searchValue: searchedWord,
                searchMode: searchMode,
                searchDisplay: fixed_word
   
              })

            }

            if (filtered_Gallery.length === 0){
 
              this.setState({

                gallery_grid: SearchData,
                search_Message: 'No Results Found',
                gallery_filtered: false,
                searchMode: searchMode,
                searchDisplay: ''
              })
            }
      }
    }

    searchReset(){
 
      if (this.state.page==='Apps'){
        this.setState({
          gallery_grid: AppData,
          search_Message: '',
          gallery_filtered: false,
          searchValue: null,
          searchMode: this.state.page,
          searchPlaceholder: '...find what?',
          searchDisplay: ''
        })
      }

      if (this.state.page==='Shop'){
        this.setState({
          gallery_grid: ProductData,
          search_Message: '',
          gallery_filtered: false,
          searchValue: null,
          searchMode: this.state.page,
          searchPlaceholder: '...find what?',
          searchDisplay: ''
        })
      }
      

    }

    menuOn(){

      if (!this.state.menu) {

        this.setState({
          menu: true
        })
 
      }

      else {
        this.setState({
          menu: false
        })
      }


    }
    
    track(context,data){
 
      const trackingId_01 = "UA-118761430-1";
      const trackingId_02 = "G-XS4ZKCWJEC";

      ReactGA.initialize(trackingId_01);
      ReactGA.pageview(`${context} ${data}`);

      ReactGA.initialize(trackingId_02);
      ReactGA.pageview(`${context} ${data}`);

    }

   
    render(){
 
      var currentGridContent = this.state.gallery_grid
      var currentFocusContent = ''
      pageIntro = ''
       
      if (this.state.page === 'Entrance') {
        pageIntro = <NewEntrance pageSelector = {this.pageSelect}/> 
      }

      if (this.state.page === 'Apps') {
        pageIntro = <Apps />
      }  

      if (this.state.page === 'Shop') {
        pageIntro = <Shop />
      }

      if (this.state.page === 'PrivacyPolicy') {
        pageIntro = <PrivacyPolicy />
      }
 

      if (this.state.focus) {
        currentFocusContent = currentGridContent.filter((item,index) => {
          if (item.name === this.state.focus){
            return {
              source: item.source,
              name: item.name,
              link: item.link
            }
          }
          else{return null}
        })
      }
  
      return(
      <ul className = 'dashboard_List'> 
 
        <li className = 'li_gallery_grid_container'>{ pageIntro }</li>

        {(this.state.page === 'Apps' || this.state.page === 'Shop')
          ? <li key='Headline' className = 'li_gallery_grid_container'>
              <Search 
                inputValue = {this.state.searchValue}
                goSearch = {this.searchPage}
                gallery_filtered = {this.state.gallery_filtered}
                search_Message = {this.state.search_Message}
                searchReset = {this.searchReset}
                searchPlaceholder = {this.state.searchPlaceholder}
                searchMode = {this.state.searchMode}
                searchDisplay = {this.state.searchDisplay}
              />
            </li>
          : null
        }
 
        {(this.state.content === 'Gallery') 
        ? <li key='Gallery' className = 'li_gallery_grid_container'> 
               <Gallery gridContent = {currentGridContent}
                        currentPage = {this.state.page}
                        focusSelect = {this.focusSelect}
                        focusOff = {this.focusOff}
                        inputValue = {this.state.searchValue}
                        goSearch = {this.searchPage}
                        search_Message = {this.state.search_Message}
                        searchReset = {this.searchReset}
                        searchMode = {this.state.searchMode}
                        />
                        </li>
        : null
        } 
   
        {(this.state.focus) 
        ? <li key = 'Focus' > <Focus focusContent = {currentFocusContent[0]}
                      focusOff ={this.focusOff}/></li>
        : null}

        

        <li key = 'NavBar' > <TopBar currentPage = {this.state.page}
                     pageSelector = {this.pageSelect}
                     focusOff ={this.focusOff}
                     menuOn = {this.menuOn}
                     menuStatus = {this.state.menu}/>
        </li>

        {(this.state.menu) 
        ? <li key = 'MobileMenu' > <Menu pageSelector = {this.pageSelect}
                  menuStatus = {this.state.menu}
                  menuOn = {this.menuOn}
              />
          </li>
        : null}
        
        <li key = 'Footer' > <Footer pageSelector = {this.pageSelect} /></li>
        
      </ul>
      )
    }

}