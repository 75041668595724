import React from 'react'

export default function PrivacyPolicy() {

  return(
    
    <div className='privacy_policy_container'>
    <div className ='text_privacy_policy_main'>
       <p align="center"><strong>GOOD GLUE APPS PRIVACY POLICY </strong><br></br></p>
       <div align="center">
       
          <p align="center">
            This privacy policy applies to all of the Good Glue apps.
          </p>
          <p align="center">
            We understand that privacy is important to the users of our apps, especially when used by children.<br></br>
            This statement governs our privacy policies with respect to those users of Good Glue Apps.</p>
          <p align="center">
            <strong>&quot;Personally Identifiable Information&quot; </strong>
            <br></br>
            refers to any information that identifies or can be used to identify, contact, or locate the person to whom such information pertains, including, but not limited to, name, address, phone number, fax number, email address, financial profiles, social security number, and credit card information. <br></br>
            Personally Identifiable Information does not include information that is collected anonymously (that is, without identification of the individual user) or demographic information not connected to an identified individual.</p>
          <p align="center"><strong>What Personally Identifiable Information is collected? </strong><br></br>
            We never collect any user information from within our apps.</p>
          <p align="center">What happens if the Privacy Policy Changes?
          <br></br>
            In the unlikely event that we change our current privacy policy we will let our app users know about changes to our privacy policy by posting such changes on this Site.<br></br>
          </p>
          <p align="center">
            © 2013 Good Glue Apps, All rights reserved.<br></br>
            Unauthorized duplication or publication of any materials from this Site is expressly prohibited.
          </p>
        
      </div>
      <p align="center"><br></br>
      </p>
    </div>
    </div>
  )

}