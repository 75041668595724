import React from 'react'
import '../TopBar.css'
import '../Mobile_TopBar.css'
import GG_SM_Image from '../images/GG_logo_small.png'
import Home_Image from '../images/HOME_menu.png'
import Apps_Image from '../images/APPS_menu.png'
import Shop_Image from '../images/SHOP_menu.png'
//import Yes_Image from '../images/YES_menu.png'
import Yes_Image_Align_Right from '../images/YES_menu_2.png'
//import News_Image from '../images/NEWS_menu.png'
//import Toys_Image from '../images/TOYS_menu.png'
import Mobile_Menu from '../images/mobile_menu.png'
import Mobile_Menu_Exit from '../images/mobile_menu_close.png'
import Amazon from '../images/New_Amazon_Badge.png'
import AppStore from '../images/AppleAppStore.png'
import { isMobile } from 'react-device-detect';
 
export default class TopBar extends React.Component {
 
  render() {
  
    let mobile_Detect = isMobile
    let emailSubject = `${'Yes! I want to receive emails about new Good Glue Apps!'}`
    var topBar_content = null

    //Desktop
    if (!mobile_Detect) {

      topBar_content = 
         
          <ul className='barContainer'>
            <li className='bar_list_item_logo' onClick={() => this.props.pageSelector('Entrance')}>
              <img className='bar_image_logo' src={GG_SM_Image} alt='Go to Front'/>
            </li>

            <li>
              <ul className='barList' >
                
                <li className='bar_list_item' onClick={() => this.props.pageSelector('Entrance')}>
                  <img className='bar_image_pages' style={{ width: '100px'}} src={Home_Image} alt='Go to Apps'/>
                </li>

                <li className='bar_list_item' onClick={() => this.props.pageSelector('Apps')}>
                  <img className='bar_image_pages' src={Apps_Image} alt='Go to Apps'/>
                </li>
                 
                <li className='bar_list_item' onClick={() => this.props.pageSelector('Shop')}>
                  <img className='bar_image_pages' src={Shop_Image} alt='Go to the Shop'/>
                </li>

                {/* <li className='bar_list_item' onClick={() => this.props.pageSelector('Shop')}>
                  <img className='bar_image_pages' src={Toys_Image} alt='Go to the Shop'/>
                </li>

                <li className='bar_list_item' onClick={() => this.props.pageSelector('Shop')}>
                  <img className='bar_image_pages' src={News_Image} alt='Go to the Shop'/>
                </li> */}

                <li key="04">
                  <a href = {`mailto:info@goodglue.com?subject=${emailSubject}`} alt = 'Sign Up With Good Glue App Release Notification and Newsletter'>
                    <img className='menu_image_badges' src={Yes_Image_Align_Right} alt='Sign Up With Good Glue App Release Notification and Newsletter'/>
                  </a>
                </li>
 
              </ul>
            </li>

            <li className='bar_list_item_badge'>
              <ul className='barList'>
                {/* <li className='bar_list_item'>
                  <a href = 'mailto:info@goodglue.com?subject=Yes%20Please%20Notify%20Me%20About%20New%20Good%20Glue%20Apps!' alt = 'Sign Up With Good Glue App Release Notification and Newsletter'>
                    <img className='bar_image_badges' src={Yes_Image} alt='Sign Up With Good Glue App Release Notification and Newsletter'/>
                  </a>
                </li> */}
                <li className='bar_list_item'><a href='https://www.amazon.com/shops/A3SYZRXDPT230V'><img className='bar_image_badges_wide' src={Amazon} alt='Go to Amazon'/></a></li>
                <li className='bar_list_item'><a href='https://apps.apple.com/us/developer/good-glue/id312914730?ign-mpt=uo%253D4#see-all/i-phonei-pad-apps'><img className='bar_image_badges' src={AppStore} alt='Go to App Store'/></a></li>
              </ul>
            </li>

          </ul>

    }

    //Mobile
    if (mobile_Detect) {
 
      topBar_content = 
      
          <ul className='mobile_barContainer'>
            <li className='bar_list_item_logo' onClick={() => this.props.pageSelector('Entrance')}>
              <img className='bar_image_logo' src={GG_SM_Image} alt='Go to Front'/>
            </li>

            <li>
              <ul className='barList' >
                <li className='bar_list_item_badge'>
                  <ul className='barList'>
                    <li className='bar_list_item'>
                      {(this.props.menuStatus)
                        ? <img className='mobile_Hamburger_menu_img'
                            src={Mobile_Menu_Exit}
                            alt='Mobile Menu Drop Down'
                            onClick={() => {this.props.menuOn()}}
                           />
                        : <img className='mobile_Hamburger_menu_img'
                            src={Mobile_Menu}
                            alt='Mobile Menu Drop Down'
                            onClick={() => {this.props.menuOn()}}
                          />
                          
                      }
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

    }
     
    return(
      <div className='bar'>
        { topBar_content }
      </div>
      
    )

  }

}