import React from 'react'
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect'
import '../App.css';
import '../Mobile_App.css'
import Apps_Trucks from '../images/Category_Trucks.png'
import Apps_Others from '../images/Category_Matching_Puzzles.png'
import Apps_Bundles from '../images/Category_Bundles.png'

export default class Gallery extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      show_details: null,
      show_zoom: null,
      show_hover: null,
      searchMode: this.props.searchMode
    }

    this.handleMouseOver = this.handleMouseOver.bind(this)
 
  }

  componentDidMount() {

    const trackingId_01 = "UA-118761430-1";
    const trackingId_02 = "G-XS4ZKCWJEC";
    ReactGA.initialize(trackingId_01);
    ReactGA.initialize(trackingId_02);
     
  }

  handleMouseOver(index) {
 
    if (this.state.show_hover === null) {

      this.setState({
        show_hover: index
      })

    }
 
  }

  handleMouseLeave() {
 
    if (this.state.show_hover !== null) {

      this.setState({
        show_hover: null
      })

    }
 
  }

  
  render(){

    var fullContent = null
    var newListItem = null
    var headerContent = null

    //the headers
    if (this.props.currentPage === 'Apps') {
 
      switch (this.props.inputValue){

          case null:
            headerContent = 
            <>
              <li onClick ={() => {this.props.goSearch('header_vehicles')}}><img className='category_header_img' src = {Apps_Trucks} alt='Show Trucks'/></li>
              <li onClick ={() => {this.props.goSearch('header_matchpuzzles')}}><img className='category_header_img' src = {Apps_Others} alt='Show Others'/></li>
              <li onClick ={() => {this.props.goSearch('header_bundles')}}><img className='category_header_img' src = {Apps_Bundles} alt='Bundles'/></li>
            </>
          break

          case 'Trucks and Vehicle Apps':
            headerContent = 
            <>
              <li onClick ={() => {this.props.goSearch('header_vehicles')}}><img className='category_header_img' src = {Apps_Trucks} alt='Show Trucks'/></li>
            </>
          break

          case 'Matching and Puzzle Apps':
            headerContent = 
            <>
              <li onClick ={() => {this.props.goSearch('header_matchpuzzles')}}><img className='category_header_img' src = {Apps_Others} alt='Show Matching and Puzzle Apps'/></li>
            </>
          break

          case 'App Bundles':
            headerContent = 
            <>
              <li onClick ={() => {this.props.goSearch('header_bundles')}}><img className='category_header_img' src = {Apps_Bundles} alt='Show App Bundles'/></li>
            </>
          break

          default:
            headerContent = null  
          break
      }

    }

    //GRID
    if (!isMobile){
 
      let listContent = this.props.gridContent.map((item,index) => {
   
        if (this.props.currentPage === 'Apps') {
          newListItem = 
          <li key={item.name + index} className='gallery_li' >
            <div key={index} 
                 onMouseEnter={() => this.handleMouseOver(item.id)}
                 onMouseLeave={() => this.handleMouseLeave()}>

                <ReactGA.OutboundLink
                  eventLabel = { item.name }
                  to = { item.link }
                  target = 'blank'
                  alt={`Go check out ${ item.name } in the Apple App Store`}
                >
  
                    {(this.state.show_hover === item.id)
                    ? <img 
                        key={ item.id }
                        src={ item.source_hover }
                        alt={`Go check out ${ item.name } in the Apple App Store`}
                      />
                    : <img 
                        key={ item.id }
                        src={ item.source_thumb }
                        alt={`Go check out ${ item.name } in the Apple App Store`}
                    />
                    }  
               
                <div className='item_title_container' ><h4 className='item_title'>{item.name}</h4></div>
                </ReactGA.OutboundLink>
          </div>
          </li>
        }
  
        if (this.props.currentPage === 'Shop') {
          newListItem = 
          <li key={item.name + index} className='gallery_li'>
            <div key={index}
                 className='item_container'
                 onMouseEnter={() => this.handleMouseOver(item.id)}
                 onMouseLeave={() => this.handleMouseLeave()}
            >
              
            {(this.state.show_hover === item.id)
              ? <img className='grid_Images'
                key={ item.id }
                src={ item.hover_image }
                alt={ item.name }
                onClick = {(e) => this.props.focusSelect(item.name)}
                />
              : <img className='grid_Images'
                key={ item.id }
                src={ item.source_thumb }
                alt={ item.name }
                onClick = {(e) => this.props.focusSelect(item.name)}
                />
              }       
  
              <div className='item_title_container' >
                <h4 className='item_title'>{item.name}</h4>
              </div>
            </div>
          </li>
        }
          
        return newListItem
    
      })
      

      if (this.props.currentPage === 'Apps') {
         
        fullContent =  
          <div className='category_header' >
            <ul className='category_header_ul' >
              {headerContent}
            </ul>
            
            <ul className='grid-container'>
              {listContent}
            </ul>
          </div>
      }

      if (this.props.currentPage === 'Shop') {

        fullContent =
          
            <ul className='grid-container'>
              {listContent}
            </ul>
           
        
      } 

      

    }

    //SINGLE COLUMN LIST
    if (isMobile){

      let listContent = this.props.gridContent.map((item,index) => {
  
        if (this.props.currentPage === 'Apps') {
          
          newListItem = 
             
              <div key={ item.id }>
                 
                  <div className = 'mobile_App_container'>
                  
                    <div className='mobile_dual_item_container'>

                          <ReactGA.OutboundLink
                              eventLabel = { item.name }
                              to = { item.link }
                              target = 'blank'
                              alt={`Go check out ${ item.name } in the Apple App Store`}
                              >
                              <img 
                                src={ item.source_thumb }
                                alt={`Go check out ${ item.name } in the Apple App Store`}
                              />
                          </ReactGA.OutboundLink>

                          <ReactGA.OutboundLink
                              eventLabel = { item.name }
                              to = { item.link }
                              target = 'blank'
                              alt={`Go check out ${ item.name } in the Apple App Store`}
                              >
                              <img 
                                src={ item.source_hover }
                                alt={`Go check out ${ item.name } in the Apple App Store`}
                              />
                          </ReactGA.OutboundLink>
   
                    </div>
                
                  </div>
                
                  <div className='item_title_container' >
                    <ReactGA.OutboundLink
                      eventLabel = { item.name }
                      to = { item.link }
                      target = 'blank'
                      alt={`Go check out ${ item.name } in the Apple App Store`}>
                      <h4 className='item_title'>{item.name}</h4>
                    </ReactGA.OutboundLink>
                  </div>
                   
              </div>
   
        }
  
        if (this.props.currentPage === 'Shop') {

          newListItem = 
           
            <div key={ item.id }
                 className='mobile_item_container'
            >
               
               <img className='grid_Images'
                
                src={ item.source_thumb }
                alt={ item.name }
                onClick = {(e) => this.props.focusSelect(item.name)}
                />
               
              <div className='item_title_container' ><h4 className='item_title'>{item.name}</h4></div>
            </div>

        }
          
        return newListItem
    
      })

      if (this.props.currentPage === 'Apps') {

        fullContent =  
          <div className = 'mobile_container'>
            <div className = 'category_header'>
              <ul className='category_header_ul'>
                {headerContent}
              </ul>
            </div>
            <div className='mobile_grid_Apps_container'>
              {listContent}
            </div>
          </div> 

      }

      if (this.props.currentPage === 'Shop') {

        fullContent =  
          <div className = 'mobile_container'>
            <div className='mobile_grid_Shop_container'>
              {listContent}
            </div>
          </div> 

      }
 
    }
 
    return(
 
      <div>{ fullContent }</div>
 
    )
  }

}

