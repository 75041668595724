import React from 'react'
import '../App.css';
import '../Mobile_App.css'
import GoodGlueLogo from '../images/GG_LOGO_no_alpha.png'
import ShopTitle from '../images/Header_Page_Shop.png'

export default class Shop extends React.Component {
 
  render(){
    return(

      <div className='textContainer'>
        <br></br>
        <br></br>
        <img src={ GoodGlueLogo } style={{width: '220px'}} alt='Good Glue Logo'/>
        <img src={ ShopTitle } style={{width: '220px'}} alt='Shop!'/>
        <h3 className='text_Shop'>
          All of our fun Good Glue products are produced with the same
          care and attention to detail as our apps. 
          Each item features artwork from our popular apps.<br></br><br></br><b>ENJOY!</b>
        </h3>
      </div>
 
    )
  }

}