import React from 'react'
import '../App.css';
import '../Mobile_App.css'
import GoodGlueLogo from '../images/GG_LOGO_no_alpha.png'
import AppTitle from '../images/Header_Apps.png'

export default class Apps extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      search_word: ""

    }
 
  }
 
  render(){
 
    return(

      <div className='textContainer'>
        <br></br>
        <br></br>
        <img src={ GoodGlueLogo } style={{width: '220px'}} alt='Good Glue Logo'/>
        <img src={ AppTitle } style={{width: '220px'}} alt='Apps!'/>
        <h3 className='text_Apps'>
        All of our GoodGlue apps are educational as well as entertaining.
        Each app features a world of hand drawn illustrations and animations
        that inspire curiosity, interaction and learning.<br></br><br></br>
        <b>HAVE FUN!</b>
        </h3>
      </div>
 
    )
  }

}