import React from 'react'
import Tile from '../images/FoodScrapRecyclingTruck.png'
import TShirt from '../images/T-ShirtEntrance.png'
import GTPlacemat from '../images/GTPlacematEntrance.png'
import GTPoster from '../images/GTPosterEntrance.png'



export default class Shuffle extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      viewportWidth: window.innerWidth
    }

  }

  render() {

    var ShuffleContent = null

    ShuffleContent =
      <>
        <h1 className='entranceText' style={{ fontSize: '2.0em', color: 'rgb(252, 135, 1)' }} >...</h1>

        <h1 className='entranceText' style={{ fontSize: '2.0em', color: 'rgb(0,81,165)' }}>Exciting News!</h1>

        <ul style = {{paddingLeft: '0'}}>
        <li>
            <p className='entranceText' style={{ fontSize: '1.2em' }} >
            <strong style={{ verticalAlign: 'text-bottom', fontSize: '1.7em', color: 'rgb(0,81,165)' }}>* </strong>
             Fun new Guide to Garbage Truck Posters are now available in the <strong style={{ fontSize: '1.0em', color: 'rgb(252, 135, 1)' }} onClick={()=>{this.props.selectPage('Shop')}}>SHOP!</strong>
            </p>
            <img id='tile1' className='mover' src={GTPoster} alt='app action shot' onClick={()=>{this.props.selectPage('Shop')}}/>
            <h1 className='entranceText' style={{ fontSize: '2.0em', color: 'rgb(252, 135, 1)' }} >...</h1>
          </li>
         <li>
            <p className='entranceText' style={{ fontSize: '1.2em' }} >
            <strong style={{ verticalAlign: 'text-bottom', fontSize: '1.7em', color: 'rgb(0,81,165)' }}>* </strong>
             Fun new Guide to Garbage Trucks placemats are now available in the <strong style={{ fontSize: '1.0em', color: 'rgb(252, 135, 1)' }} onClick={()=>{this.props.selectPage('Shop')}}>SHOP!</strong>
            </p>
            <img id='tile1' className='mover' src={GTPlacemat} alt='app action shot' onClick={()=>{this.props.selectPage('Shop')}}/>
            <h1 className='entranceText' style={{ fontSize: '2.0em', color: 'rgb(252, 135, 1)' }} >...</h1>
          </li>
          <li>
            <p className='entranceText' style={{ fontSize: '1.2em' }} >
            <strong style={{ verticalAlign: 'text-bottom', fontSize: '1.7em', color: 'rgb(0,81,165)' }}>* </strong>
             Fun new kid's T-shirts featuring truck favorites are now available in the <strong style={{ fontSize: '1.0em', color: 'rgb(252, 135, 1)' }} onClick={()=>{this.props.selectPage('Shop')}}>SHOP!</strong>
            </p>
            <img id='tile1' className='mover' src={TShirt} alt='app action shot' onClick={()=>{this.props.selectPage('Shop')}}/>
            <h1 className='entranceText' style={{ fontSize: '2.0em', color: 'rgb(252, 135, 1)' }} >...</h1>
          </li>
          <li>
            <p className='entranceText' style={{ fontSize: '1.2em' }} >
            <strong style={{ verticalAlign: 'text-bottom', fontSize: '1.7em', color: 'rgb(0,81,165)' }} >* </strong>
              Good Glue recently had the pleasure of collaborating with
              Novamont, a Bioplastics manufacturer, in making a fun new <strong>FREE</strong> truck app focused on the importance of composting and food scrap recycling called:
            </p>
            <a href='https://apps.apple.com/us/app/food-scrap-recycling-truck/id1513233633' alt='link to Food Scrap Recycling Truck'>
              <h1 className='entranceText' style={{ fontSize: '1.7em', color: 'rgb(0,81,165)' }}>Food Scrap Recycling Truck</h1>
              <img id='tile1' className='mover' src={Tile} alt='app action shot' />
            </a>
          </li>
        </ul>


      </>

    return (

      <div>
        {ShuffleContent}
      </div>

    )

  }


}