import React from 'react'
import { isMobile } from 'react-device-detect'
import '../Mobile_App.css'
import ShowAllApps from '../images/show_all_apps.png'
import ShowAllStuff from '../images/show_all_stuff.png'
 
export default class Search extends React.Component {

  constructor(props){
    super(props)
     
    this.state = {
      input_text: '',
      placeholder: this.props.searchPlaceholder,
      searchMode: this.props.searchMode
    }
  }
 
  handleChange(e){

    this.setState({
      input_text: e.target.value,
      placeholder: this.props.searchPlaceholder,
    })

  }

  afterSearch(e){

    if (e.value !== ''){

      this.setState({
        input_text: '',
        placeholder: this.state.placeholder
      })

    }
 
  }
 

  render(){
 
    let searchBar_content = null
    let showAll = null
    
    if (this.props.searchMode === 'Apps' ||
        this.props.searchMode === 'Vehicles' ||
        this.props.searchMode === 'MatchPuzzles' ||
        this.props.searchMode === 'Bundles'
        ){
      showAll = <><img className='category_header_img' src={ShowAllApps} alt='Show all the Apps' onClick={() => this.props.searchReset()}/></>
    }

    if (this.props.searchMode === 'Shop'){
      showAll = <><img className='category_header_img' src={ShowAllStuff} alt='Show all the Stuff' onClick={() => this.props.searchReset()}/></>
    }

    if (!isMobile) {

      searchBar_content =  
      
        <ul >
        <li>
          <form onSubmit = {(e) => {
            e.preventDefault();
            this.afterSearch(e);
            this.props.goSearch(this.state.input_text);
            }}>
              <input className = 'search_input' 
                   type='text'
                   name = 'word'
                   placeholder = {this.state.placeholder}
                   value = {this.state.input_text}
                   onChange = {(e) => this.handleChange(e)}
              />
              <button className = 'search_button' type='submit'>Search</button>
              {(this.props.gallery_filtered) 
              ? <li className='search_message'>
                  <p>Showing all results for <span style = {{color: 'rgb(252, 135, 1)' }}>"{this.props.searchDisplay}"</span></p>
                  {showAll}
                </li>
              : <li className = 'search_message'><p>{this.props.search_Message}</p></li>
              }
          </form>
        </li>
        
      </ul>

    }

    if (isMobile) {
 
      searchBar_content =  
       
          <form 
            onSubmit = {(e) => {e.preventDefault();
                                this.afterSearch(e);
                                this.props.goSearch(this.state.input_text)}
          }>
            <input className = 'mobile_search_input' 
              type='text'
              name = 'word'
              placeholder = " for what?"
              value = {this.state.input_text}
              onChange = {(e) => this.handleChange(e)}
            /> 
            <button className = 'mobile_search_button' type='submit'>Search</button>
            {(this.props.gallery_filtered) 
              ? <div className='search_message'>
                  <p>Showing all results for <span style = {{color: 'rgb(252, 135, 1)' }}>"{this.props.searchDisplay}"</span></p>
                   {showAll}
                </div>
              : <div className = 'search_message'><p>{this.props.search_Message}</p></div>
              }
        </form>
      
        
    }
 
    return(
 
      <div className='search_container'>
        { searchBar_content }
      </div>

    )

  }

}