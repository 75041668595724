import React from 'react'
import './App.css'
import './Mobile_App.css'
import Dashboard from './components/Dashboard'

function App() {
 
  return (
    <div className="App">
       <Dashboard/>
    </div>
  );
}

export default App;
