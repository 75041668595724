import React from 'react'
import '../App.css';
import '../Mobile_App.css'
import '../Shuffle.css'
import GoodGlueLogo from '../images/Big_top2.png'
import ShopTitle from '../images/Header_Shop.png'
import AppTitle from '../images/Header_Apps.png'
import Shuffle from './Shuffle'
 
export default class NewEntrance extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tile_pos_origin: 0,
      tile_pos_current: 0,
      timer: 0,
      shiftActive: true,
      viewportWidth: window.innerWidth
    }
  
  }
    
  render(){
 
    return(
      <div className='entrance_Container'>
         
              <div className='entrance_top_image_container'> 
                <img src={ GoodGlueLogo } alt='Good Glue Logo'/>
              </div>
 
              <h1 className='entranceText'>
                Welcome
                <br></br>
                to the world of
                <br></br> 
                <strong style = {{ fontSize: '1.0em', color: 'rgb(252, 135, 1)' }}>
                Good Glue
                </strong>
                <br></br> 
                Maker of Apps
                <br></br>
                and other fun stuff 
                <br></br> 
                for kids!
              </h1>
 
              <div className='entrance_Nav_container'>
                <img src={AppTitle} alt='Go to Apps' onClick={() => this.props.pageSelector('Apps')}/>
                <img src={ShopTitle} alt='Go to the Shop' onClick={() => this.props.pageSelector('Shop')}/>
              </div> 

              <div className='mover_container'>
                <Shuffle 
                  viewportWidth = {this.state.viewportWidth}
                  selectPage = {this.props.pageSelector}
                  />
              </div>
 
      </div>
 
    )
  }

}
 