//import hover_test from '../images/Truck_Hovers/Hover_Garbage_Truck.png';

 
// const app_titles = [
//   'Alphabet Truck',
//   'Cement Truck',
//   'Crane Truck',
//   'Drill Truck',
//   'Dump Truck',
//   'Dump Truck Skid Loader',
//   'Egg Hunt Truck',
//   'Explosive Demolition Truck',
//   'Fire Truck',
//   'Fire Truck Airport Rescue',
//   'Fire Boat',
//   'Fuel Tanker Truck',
//   'Garbage Truck',
//   'Garbage Truck Austin Tx',
//   'Garbage Truck Brushy Pick Up',
//   'Garbage Truck Bulky Trash Pick Up',
//   'Garbage Truck Dumpster Pick Up',
//   'Garbage Truck Los Angeles Ca',
//   'Garbage Truck Phoenix Az',
//   'Garbage Truck Snow Time',
//   'Ghost Catcher Truck',
//   'Ice Cream Truck',
//   'Junk Truck',
//   'Log Truck',
//   'Mail Truck',
//   'Milk Tanker Truck',
//   'Oxygen Tanker Truck',
//   'Package Delivery Truck',
//   'Pizza Truck',
//   'Police Truck',
//   'Race Car',
//   'Railroad Boom Truck',
//   'Recycling Truck',
//   'Road Roller',
//   'School Bus',
//   'Snow Plow Truck',
//   'Street Sweeper',
//   'Tow Truck',
//   'Tow Truck Max',
//   'Tree Spade Truck',
//   'Wood Chipper Truck',
//   'Wrecking Ball Truck',
//   'Good Glue Trucks Classic Truck Pack',
//   'Good Glue Trucks Classic Truck Pack 2',
//   'Good Match Trucks',
//   'Good Puzzle Trucks'

// ]

const app_links = [
  'alphabet-truck/id1021347052?mt=8',
  'cement-truck/id513476722?mt=8',
  'crane-truck/id602047861?mt=8',
  'drill-truck/id873930257?mt=8',
  'dump-truck/id450283054?mt=8',
  'dump-truck-skid-loader/id1137762562?mt=8',
  'egg-hunt-truck/id1091622010?mt=8',
  'explosive-demolition-truck/id1253612601?mt=8',
  'fire-truck/id491759799?mt=8',
  'fire-truck-airport-rescue/id1208275937?mt=8',
  'fire-boat/id951463440?mt=8',
  'fuel-tanker-truck/id732235470?mt=8',
  'garbage-truck/id420587663?mt=8',
  'garbage-truck-austin-tx/id621664128?mt=8',
  'garbage-truck-brushy-pick-up/id929725067?mt=8',
  'garbage-truck-bulky-trash-pick-up/id1085971850?mt=8',
  'garbage-truck-dumpster-pick-up/id477718570?mt=8',
  'garbage-truck-los-angeles-ca/id1027454432?mt=8',
  'garbage-truck-phoenix-az/id514829365?mt=8',
  'garbage-truck-snow-time/id498956516?mt=8',
  'ghost-catcher-truck/id1223187420?mt=8',
  'ice-cream-truck/id544466605?mt=8',
  'junk-truck/id920543911?mt=8',
  'log-truck/id519598710?mt=8',
  'mail-truck/id531032234?mt=8',
  'milk-tanker-truck/id938746289?mt=8',
  'oxygen-tanker-truck/id1079266470?mt=8',
  'package-delivery-truck/id662207393?mt=8',
  'pizza-truck/id1144202135?mt=8',
  'police-truck/id733935148?mt=8',
  'race-car/id504733860?mt=8',
  'railroad-boom-truck/id511633235?mt=8',
  'recycling-truck/id433645198?mt=8',
  'road-roller/id500932528?mt=8',
  'school-bus/id545236525?mt=8',
  'snow-plow-truck/id493065790?mt=8',
  'street-sweeper/id470690957?mt=8',
  'tow-truck/id503454396?mt=8',
  'tow-truck-max/id1178535474?mt=8',
  'tree-spade-truck/id1218149488?mt=8',
  'wood-chipper-truck/id753621884?mt=8',
  'wrecking-ball-truck/id665790630?mt=8',
  'good-glue-trucks-classic-truck-pack/id1023312535?mt=8',
  'good-glue-trucks-classic-truck-pack-2/id1030323029?mt=8',
  'good-match-trucks/id915523218?mt=8',
  'good-puzzle-trucks/id1134223227?mt=8'
]
   
//Get all images from any folder -- !note: file names are utilized as names
function importAll(r) {
  return r.keys().map(r);
}
 
//get all images in App trucks folder
const All_app_icon_images = importAll(require.context('../images/App_Icons/', false, /\.(png|jpe?g|svg)$/));

//get all images in App trucks folder
const Hover_images = importAll(require.context('../images/App_Hovers/Truck_Hovers/', false, /\.(png|jpe?g|svg)$/));
 
//Build App collection 
let AppData = All_app_icon_images.map((item,index) => {

  let new_Name = String(item)

  var mySubString = new_Name.substring(
    new_Name.lastIndexOf("a/") + 2, 
    new_Name.indexOf(".")
  );
 
  let final_name =  mySubString.replace(/_/g,' ')

  var app_type = ''

  if (final_name.includes('Truck') ||
      final_name.includes('Bus') ||
      final_name.includes('Sweeper') ||
      final_name.includes('Boat')) {

    app_type = 'vehicles'

  }

  if (final_name.includes('GoodMatch') || final_name.includes('GoodPuzzle')) {

    app_type = 'matchpuzzles'

  }

  if (final_name.includes('Good Glue')) {

    app_type = 'bundles'

  }
 
  //search links for the name
  var link_Use = '#'
  var hover_image_Use = '#'

  //App links
  var link_Url = `https://itunes.apple.com/us/app/`
  var link_Name_Clean = ''
 
  for (index = 0; index < app_links.length; index += 1) {
 
      link_Name_Clean = app_links[index].replace(/-/g,' ')
      link_Name_Clean = link_Name_Clean.split('/')[0]
 
      if (link_Name_Clean.toLowerCase() === final_name.toLowerCase()) {
 
          if (app_type === 'bundles'){
 
            link_Url = `https://itunes.apple.com/us/app-bundle/`

          }
  
          link_Use = `${link_Url}${app_links[index]}`

          break
  
      }

  }

  for (index = 0; index < Hover_images.length; index += 1) {

      let removeThis = '/static/media/'

      var image_Name_Replace = Hover_images[index].replace(/_/g,' ')

      var image_Name_Remove = image_Name_Replace.split(removeThis)[1]
  
      if (image_Name_Remove) {
        
        var image_Name_Clean = image_Name_Remove.split('.')[0]
 
        if (image_Name_Clean.toLowerCase() === final_name.toLowerCase()) {
 
          hover_image_Use = Hover_images[index]

          break
 
        }
      }
  
  }

  let lc_final_name = final_name.toLowerCase()

  let name_pieces = lc_final_name.split(' ')
 
  let general_terms = ['kids','toddler','baby']

  let specific_terms = [app_type,lc_final_name]

  name_pieces.push(...general_terms)
    
  if (app_type === 'bundle') {
      specific_terms.push(...['vehicles','trucks','bundles','app bundle','app bundles'])
    }
  
  //Garbage Trucks
  if (name_pieces.includes('garbage') || name_pieces.includes('recycling')) {

      specific_terms.push(...['vehicles','trucks','trash','garbage man','can','cans','bins','bin',
                         'collection','sanitation','recycling','recycle',
                         'dumpster','trashcan','green'])
  }

  //Contruction Type Trucks
  if (name_pieces.includes('crane') ||
      name_pieces.includes('dump') ||
      name_pieces.includes('cement') ||
      name_pieces.includes('skid') ||
      name_pieces.includes('drill') ||
      name_pieces.includes('wrecking') ||
      name_pieces.includes('explosive') ||
      name_pieces.includes('roller')) {

      specific_terms.push(...['vehicles','trucks','construction','bulldozer','digger','work','build',
                            'dumper','quarry'])
  }

  //Fire
  if (name_pieces.includes('fire')) {
      specific_terms.push(...['vehicles','trucks','firefighter','helicopter','ladder','blaze','forest',
                           'fireman','pumper','hydrant','rescue','plane','station',
                           'pet','sprayer'])
  }

  //Police
  if (name_pieces.includes('police')) {
      specific_terms.push(...['vehicles','trucks','helicopter','robot','jail','rescue',
                         'policeman','robber','map','radar','station'])
  }

  //train stuff
  if (name_pieces.includes('crane') || name_pieces.includes('railroad')) {
      specific_terms.push(...['vehicles','trucks','train','locomotive','tracks'])
  }

  //train stuff
  if (name_pieces.includes('crane') || name_pieces.includes('race')) {
      specific_terms.push(...['vehicles','trucks','tunnel','headlights','underground'])
  }

  //kids
  if (name_pieces.includes('bus') || name_pieces.includes('cream')) {
      specific_terms.push(...['vehicles','trucks','kids','children'])
  }

  //mail
  if (name_pieces.includes('mail')) {
      specific_terms.push(...['vehicles','trucks','dogs','packages','sorting','post','post office',
                         'plane','letter','mailbox','usps','envelope'])
  }

  //cleaning
  if (name_pieces.includes('sweeper') || name_pieces.includes('bus')) {
      specific_terms.push(...['vehicles','trucks','cleaning','wash','clean'])
  }

  //repair
  if (name_pieces.includes('tow') ||
      name_pieces.includes('bus') ||
      name_pieces.includes('race')) {
      specific_terms.push(...['vehicles','trucks','fix','repair','engine','mechanic'])
  }

  //food
  if (name_pieces.includes('cream') ||
      name_pieces.includes('pizza') ||
      name_pieces.includes('milk')) {
      specific_terms.push(...['vehicles','trucks','food'])
  }

  //match and puzzle
  if (name_pieces.includes('match') ||
      name_pieces.includes('puzzle')) {
      specific_terms.push(...['matching and puzzle apps'])
  }

  //match
  if (name_pieces.includes('match')){
      specific_terms.push(...['memory','matching'])
  }
 
  //Search terms
  let terms_Use = []

  terms_Use.push(...name_pieces)
 
  if (specific_terms) {
    terms_Use.push(...specific_terms)
  }
    
  let new_Entry = {

    id: index,
    type: app_type,
    name: final_name,
    source_thumb: item,
    source_hover: hover_image_Use,
    link: link_Use,
    search_Terms: terms_Use
 
  }
 
  return new_Entry

})
 
export default AppData