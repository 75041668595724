import React from 'react'
import '../App.css'
import '../Mobile_App.css'
import Amazon from '../images/Amazon_Badge.png'
import AppStore from '../images/AppleAppStore.png'
import Mobile_Menu from '../images/mobile_menu.png'
import Mobile_Menu_Exit from '../images/mobile_menu_close.png'
import Home_Image from '../images/HOME_menu.png'
import Apps_Image from '../images/APPS_menu.png'
import Shop_Image from '../images/SHOP_menu.png'
//import News_Image from '../images/NEWS_menu.png'
//import Toys_Image from '../images/TOYS_menu.png'
import Yes_Image from '../images/YES_menu.png'
import Contact_Image from '../images/CONTACT_menu.png'

export default class Menu extends React.Component {

  constructor(props){
    super(props)
    this.state = {
       favorites: null
    }
  }
  
  render(){
 
    let emailSubject = `${'Yes! I want to receive emails about new Good Glue Apps!'}`

    return (
      <div className = 'menu_Container'>
        <div className = 'menu_Exit'>
          {(this.props.menuStatus)
             ? <img className='mobile_Hamburger_menu_img'
                 src={Mobile_Menu_Exit}
                 alt='Mobile Menu Drop Down'
                 onClick={() => {this.props.menuOn()}}
                />
             : <img className='mobile_Hamburger_menu_img'
                 src={Mobile_Menu}
                 alt='Mobile Menu Drop Down'
                 onClick={() => {this.props.menuOn()}}
                />
                          
          }

        </div>
        <ul>
          <li key="01" onClick = {() => this.props.pageSelector('Entrance')}>
            <img className='menu_image_links' src={Home_Image} alt='Go HOME'/>
          </li>
          <li key="02" onClick = {() => this.props.pageSelector('Apps')}>
            <img className='menu_image_links' src={Apps_Image} alt='Go to Apps'/>
          </li>
          <li key="03" onClick = {() => this.props.pageSelector('Shop')}>
            <img className='menu_image_links' src={Shop_Image} alt='Go to the Shop'/>
          </li>
          <li key="04">
            <a href = {`mailto:info@goodglue.com?subject=${emailSubject}`} alt = 'Sign Up With Good Glue App Release Notification and Newsletter'>
              <img className='menu_image_badges' src={Yes_Image} alt='Sign Up With Good Glue App Release Notification and Newsletter'/>
            </a>
          </li>
          <li key="05" >
            <a href='http://www.amazon.com/shops/goodglue'>
              <img className='menu_image_badges' src={Amazon} alt='Go to Amazons'/>
            </a>
          </li>
          <li key="06" >
            <a href='https://apps.apple.com/us/developer/good-glue/id312914730?ign-mpt=uo%253D4#see-all/i-phonei-pad-apps'>
              <img className='menu_image_badges' src={AppStore} alt='Go to App Store'/>
            </a>
          </li>
          {/* <li key="07" >Search</li> */}
          <li key="08" onClick = {() => console.log('CONTACT')}>
            <a href = 'mailto:info@goodglue.com?subject=Hello%20Good%20Glue'
               alt = 'Send Email to Good Glue'>
                 <img className='menu_image_links' src={Contact_Image} alt='Contact Good Glue!'/>
            </a>
          </li>
        </ul>
      </div>
    )


  }

}