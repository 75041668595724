import React from 'react'
import '../App.css'
import '../Mobile_App.css'
import ContactCloud from '../images/orange_click_contact.png'
import ContaGoodGlueCopyright from '../images/GG_copyright.png' 

export default class Footer extends React.Component {

  render(){
    return(

      <div>
        <br></br>
        <div>
          <div>
            <a href = 'mailto:info@goodglue.com?subject=Hello%20Good%20Glue' alt = 'Send Email to Good Glue'>
              <img src={ContactCloud} alt='Send Email to Good Glue'/>
            </a>
          </div>
          <div>
            <p className='text_privacy_policy'
                 onClick = {()=>{this.props.pageSelector('PrivacyPolicy')}}
                 alt = 'Good Glue Privacy Policy'>Good Glue Privacy Policy
            </p>
          </div>
          <div>
            <img src={ContaGoodGlueCopyright}
                 alt='2012 Copyright Good Glue LLC'
                 style = {{width: '240px'}}
            />
          </div>
        </div>
      </div>
 
    )
  }

}

