import React from 'react'
import '../App.css'
import '../Mobile_App.css'
import { isMobile } from 'react-device-detect'
import BackArrow from '../images/Back_mini_arrow.png'
import Amazon from '../images/amazon_sm.png'
import Teespring from '../images/Teespring_logo-button.png'


export default class Focus extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      mainImageChoice: 0,
      mainImage: this.props.focusContent.source_lg,
    }
  }

  switchImage(image_name){

    //swap main image with image from array
    let image_Choice = image_name.target.id
 
    this.setState({
      mainImageChoice: image_Choice,
      mainImage: this.props.focusContent.source_alt,
    })
     
  }
 
  render(){

    //Build alt image list
    let Alt_Images = this.props.focusContent.source_alt.map((item,index)=>{
 
        return(
          <li id= { index } key= { item }>
              <img 
                   id= { index }
                   className='focus_alt_img'
                   src={ item }
                   alt={ this.props.focusContent.name }
                   onClick = {(e) => this.switchImage(e)}
              />
          </li>
          
        )

    })

    let Focus_Content = null

    //Desktop
    if (!isMobile) {

      Focus_Content =

        <div className ='focus_Content_Container'>
          <div className='focus_Inner_Container'>
          
            <ul className='focus_list'>
              <li><h2 className='textSmaller'><b>{ this.props.focusContent.name }</b></h2></li>
              <li>
                <img src={ this.props.focusContent.source_alt[this.state.mainImageChoice] } style={{height: '370px'}}alt={ this.props.focusContent.name }
                  onClick = {(e) => this.props.focusOff(e)}/>
              </li>
              <li><div className='text_App_info_container'><p className='textSmaller'>{ this.props.focusContent.info }</p></div></li>  
              <li>
                <h3 className='textSmaller'><b>Availble now at&nbsp;</b> 
                 {(this.props.focusContent.link.includes("amazon"))
                 ? <a href={ this.props.focusContent.link }><img className='focus_amazon_img' src={Amazon} alt='Go to Amazon'/></a>
                 : <a href={ this.props.focusContent.link }><img className='focus_amazon_img' src={Teespring} alt='Go to Teespring'/></a>}
                </h3>
              </li>
              <li onClick = {(e) => this.props.focusOff(e)}>
                  <div  className='focus_exit_img_container' >
                    <img className='focus_exit_img'
                      src={ BackArrow }
                      alt={ this.props.focusContent.name }
                    />
                  </div>
              </li>
              <li id='SwitchImage'>
                <ul id='SwitchImage' 
                  className='focus_alt_img_container' >
                  {Alt_Images}
                </ul>
              </li>
            </ul>
       
          </div>
        </div>
     
    }

    //Mobile
    if (isMobile) {

      Focus_Content =

        <div className ='focus_Content_Container'>
           
            <ul className='focus_list'>
              <li><h1 className='textSmaller'><b>{ this.props.focusContent.name }</b></h1></li>
              <li>
                <img className = 'focus_main_image' src={ this.props.focusContent.source_alt[this.state.mainImageChoice] } style={{height: '370px'}}alt={ this.props.focusContent.name }
                  onClick = {(e) => this.props.focusOff(e)}/>
              </li>
              <li><div className='text_App_info_container'><p className='textSmaller'>{ this.props.focusContent.info }</p></div></li>  
              <li>
                <h3 className='textSmaller'><b>Availble now on&nbsp;</b> 
                {(this.props.focusContent.link.includes("amazon"))
                 ? <a href={ this.props.focusContent.link }><img className='focus_amazon_img' src={Amazon} alt='Go to Amazon'/></a>
                 : <a href={ this.props.focusContent.link }><img className='focus_amazon_img' src={Teespring} alt='Go to Teespring'/></a>}
                </h3>
              </li>
              <li onClick = {(e) => this.props.focusOff(e)}>
                  <div  className='focus_exit_img_container' >
                    <img className='focus_exit_img'
                      src={ BackArrow }
                      alt={ this.props.focusContent.name }
                    />
                  </div>
              </li>
              <li id='SwitchImage'>
                <ul id='SwitchImage' 
                  className='focus_alt_img_container' >
                  {Alt_Images}
                </ul>
              </li>
            </ul>
        
        </div>
     
    }
 
    return(
      <div id='focus_BG_Container' className = 'focus_BG_Container' onClick = {(e) => this.props.focusOff(e)}>
        {Focus_Content}
      </div>
    )
  }
}