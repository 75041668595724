//import React from 'react'


//A to Z Trucks
import AtoZPoster_thumb from '../images/product_AZ_trucks_poster_thumb.png'
import AtoZPoster_lg from '../images/product_AZ_trucks_poster.png'
import AtoZPoster_detail from '../images/product_AZ_trucks_poster_detail.png'
import AtoZPoster_hover from '../images/Product_Hovers/product_AZ_trucks_poster_hover.png'

import AtoZBook_thumb from '../images/product_AZ_trucks_Toddler_book_thumb.png'
import AtoZBook_lg from '../images/product_AZ_trucks_Toddler_book_lg.png'
import AtoZBook_detail from '../images/Product_Toddler_AZ_Book_image_Detail.jpg'
import AtoZBook_hover from '../images/Product_Hovers/product_AZ_toddler_book_hover.png'

import AtoZColoringBook_thumb from '../images/product_AZ_coloring_book_thumb.png'
import AtoZColoringBook_lg from '../images/product_AZ_coloring_book_lg.png'
import AtoZColoringBook_detail from '../images/product_AZ_coloring_book_detail.png'
import AtoZColoringBook_hover from '../images/Product_Hovers/product_AZ_coloring_book_hover.png'

//A to Z Trucks
import AtoZPlacemat_thumb from '../images/product_AZ_trucks_placemat_thumb.png'
import AtoZPlacemat_lg from '../images/Placemats/product_AZ_trucks_placemat.png'
import AtoZPlacemat_detail from '../images/product_AZ_trucks_poster_detail.png'

import AtoZNotebook_thumb from '../images/notebook_trucks.jpg'
import AtoZNotebook_lg from '../images/notebook_trucks.jpg'
import AtoZNotebook_detail from '../images/notebook_trucks_CU.jpg'

//GT Trucks
import GTPoster_thumb from '../images/product_GT_trucks_poster_thumb.png'
import GTPoster_lg from '../images/product_GT_trucks_poster.png'
import GTPoster_detail from '../images/product_GT_trucks_poster_detail.png'
import GTPoster_hover from '../images/Product_Hovers/product_GT_trucks_poster_hover.png'

import GTPlacemat_thumb from '../images/product_GT_trucks_placemat_thumb.png'
import GTPlacemat_lg from '../images/Placemats/product_GT_trucks_placemat.png'
import GTPlacemat_detail from '../images/product_GT_trucks_placemat_detail.png'
import GTPlacemat_hover from '../images/Product_Hovers/product_GT_trucks_placemat_hover.png'
 

//Trucks Stickers
import Classic_Stickers_thumb from '../images/product_Classic_Truck_Stickers_thumb.png'
import Classic_Stickers_lg from '../images/product_Classic_Truck_Stickers.png'
import Classic_Stickers_detail from '../images/product_Classic_Truck_Stickers_detail.png'
 

//NoteBooks
import PopsNotebook_thumb from '../images/notebook_pops.jpg'
import PopsNotebook_lg from '../images/notebook_pops.jpg'
import PopsNotebook_detail from '../images/notebook_pops_CU.jpg'

import JunkNotebook_thumb from '../images/notebook_junk.jpg'
import JunkNotebook_lg from '../images/notebook_junk.jpg'
import JunkNotebook_detail from '../images/notebook_junk_CU.jpg'

import BugsNotebook_thumb from '../images/notebook_bugs.jpg'
import BugsNotebook_lg from '../images/notebook_bugs.jpg'
import BugsNotebook_detail from '../images/notebook_bugs_CU.jpg'

import DonutsNotebook_thumb from '../images/notebook_donuts.jpg'
import DonutsNotebook_lg from '../images/notebook_donuts.jpg'
import DonutsNotebook_detail from '../images/notebook_donuts_CU.jpg'

import ComputersNotebook_thumb from '../images/notebook_computers.jpg'
import ComputersNotebook_lg from '../images/notebook_computers.jpg'
import ComputersNotebook_detail from '../images/notebook_computers_CU.jpg'

import ConesNotebook_thumb from '../images/notebook_cones.jpg'
import ConesNotebook_lg from '../images/notebook_cones.jpg'
import ConesNotebook_detail from '../images/notebook_cones_CU.jpg'

import NB_Style_Blank from '../images/blank_paper_title.png'
import NB_Style_Wide from '../images/wide_ruled_paper_title.png'
import NB_Style_College from '../images/college_ruled_paper_title.png'
import NB_Style_Half from '../images/half_ruled_paper_title.png'
import NB_Style_Graph from '../images/graph_paper_title.png'

//T-Shirts
// import T_Shirt_Green_Garbage_Truck from '../images/T_Shirts/Green_Garbage_Truck_T-Shirt.jpg'
// import T_Shirt_Blue_Garbage_Truck from '../images/T_Shirts/Blue_Garbage_Truck_T-Shirt.jpg'
// import T_Shirt_Green_Garbage_Truck_hover from '../images/Product_Hovers/Green_Garbage_Truck_T-Shirt_hover.png'
// import T_Shirt_Blue_Garbage_Truck_hover from '../images/Product_Hovers/Blue_Garbage_Truck_T-Shirt_hover.png'


//New T-Shirts
import T_Shirt_Green_Garbage_Truck from '../images/T_Shirts/Green_Garbage_Truck_T-Shirt.png'
import T_Shirt_Blue_Garbage_Truck from '../images/T_Shirts/Blue_Garbage_Truck_T-Shirt.png'
import T_Shirt_Fire_Truck from '../images/T_Shirts/Fire_Truck_T-Shirt.png'

import T_Shirt_Cement_Truck from '../images/T_Shirts/Cement_Truck_T-Shirt.png'
import T_Shirt_Masher_Truck from '../images/T_Shirts/Masher_Truck_T-Shirt.png'
import T_Shirt_Recycling_Truck from '../images/T_Shirts/Recycling_Truck_T-Shirt.png'

import T_Shirt_Dump_Truck from '../images/T_Shirts/Dump_Truck_T-Shirt.png'
import T_Shirt_Tow_Truck_Max from '../images/T_Shirts/Tow_Truck_Max_T-Shirt.png'
import T_Shirt_Ore_Mining_Truck from '../images/T_Shirts/Ore_Mining_Truck_T-Shirt.png'

import T_Shirt_Tow_Truck from '../images/T_Shirts/Tow_Truck_T-Shirt.png'
import T_Shirt_Street_Sweeper_Truck from '../images/T_Shirts/Street_Sweeper_Truck_T-Shirt.png'
import T_Shirt_Ice_Cream_Truck from '../images/T_Shirts/Ice_Cream_Truck_T-Shirt.png'

//hover images
import T_Shirt_Green_Garbage_Truck_hover from '../images/T_Shirts/Green_Garbage_Truck_T-Shirt_zoom.png'
import T_Shirt_Blue_Garbage_Truck_hover from '../images/T_Shirts/Blue_Garbage_Truck_T-Shirt_zoom.png'
import T_Shirt_Fire_Truck_hover from '../images/T_Shirts/Fire_Truck_T-Shirt_zoom.png'

import T_Shirt_Cement_Truck_hover from '../images/T_Shirts/Cement_Truck_T-Shirt_zoom.png'
import T_Shirt_Masher_Truck_hover from '../images/T_Shirts/Masher_Truck_T-Shirt_zoom.png'
import T_Shirt_Recycling_Truck_hover from '../images/T_Shirts/Recycling_Truck_T-Shirt_zoom.png'

import T_Shirt_Dump_Truck_hover from '../images/T_Shirts/Dump_Truck_T-Shirt_zoom.png'
import T_Shirt_Tow_Truck_Max_hover from '../images/T_Shirts/Tow_Truck_Max_T-Shirt_zoom.png'
import T_Shirt_Ore_Mining_Truck_hover from '../images/T_Shirts/Ore_Mining_Truck_T-Shirt_zoom.png'

import T_Shirt_Tow_Truck_hover from '../images/T_Shirts/Tow_Truck_T-Shirt_zoom.png'
import T_Shirt_Street_Sweeper_Truck_hover from '../images/T_Shirts/Street_Sweeper_Truck_T-Shirt_zoom.png'
import T_Shirt_Ice_Cream_Truck_hover from '../images/T_Shirts/Ice_Cream_Truck_T-Shirt_zoom.png'


//let line_break = <br/>
let AtoZPoster_link = `https://www.amazon.com/Official-Good-Glue-Trucks-Poster/dp/B01AGTVPFI/ref=sr_1_3?dchild=1&keywords=goodglue&qid=1593291847&sr=8-3`

let ProductData = [

  {
    id: '0001',
    name: 'The Official Good Glue A to Z Guide to Trucks Poster!',
    source_thumb: AtoZPoster_thumb,
    source_alt: [AtoZPoster_lg,AtoZPoster_detail],
    source_lg: AtoZPoster_lg,
    info:  `Our fun A to Z guide to trucks poster for truck lovers of all ages!
            Now kids can learn the alphabet with fun illustrations of trucks of
            all kinds. Kids world wide who have enjoyed the popular Good Glue Truck
            apps now have yet another way to enjoy these fun trucks. Big, bright
            and colorful, a great way for your little truck enthusiast to learn
            the alphabet. Great for early ABC learners!`,
    link_text: 'Available now on ',
    link: AtoZPoster_link,
    hover_image: AtoZPoster_hover,
    search_Terms: ['The Official Good Glue A to Z Guide to Trucks Poster!','vehicles',
                   'abc','abc\'s','alphabet','posters','poster','a to z','truck','trucks',
                   'ambulance','bucket','cement','dump','egg','fire',
                   'garbage','trash','hoist','ice','ice cream','junk','knuckle',
                   'boom','knuckleboom','log','mail','news','ore','mining','police',
                   'quarry','recycling','recycle','street','sweeper','street sweeper',
                   'streetsweeper','tow','utility','vacuum','wood',
                   'chipper','woodchipper','x-ray','xray','zoo'
                  ]
  },
  {
    id: '0004',
    name: 'The Official Good Glue A to Z Guide to Trucks Placemat!',
    source_thumb: AtoZPlacemat_thumb,
    source_alt: [AtoZPlacemat_lg,AtoZPlacemat_detail],
    source_lg: AtoZPlacemat_lg,
    info:  `Our fun A to Z guide to Trucks placemats are perfect for truck loving kiddos!
            Now kids can learn the alphabet with fun illustrations of trucks of all kinds. 
            Bright and colorful, a great way for your little truck enthusiast to learn the
            alphabet. Great for early ABC learners!`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/Official-Good-Glue-Trucks-Placemat/dp/B071DLSSDK/ref=pd_rhf_se_p_img_10?_encoding=UTF8&psc=1&refRID=K91YTGQ0R6B09QB16AHG',
    hover_image: AtoZPoster_hover,
    search_Terms: ['The Official Good Glue A to Z Guide to Trucks Placemat!',
                   'placemats','vehicles','activity','book','alphabet','restaurant',
                   'abc','abc\'s','a to z','truck','trucks',
                   'ambulance','bucket','cement','dump','egg','fire',
                   'garbage','trash','hoist','ice','ice cream','junk','knuckle',
                   'boom','knuckleboom','log','mail','news','ore','mining','police',
                   'quarry','recycling','recycle','street','sweeper','street sweeper',
                   'streetsweeper','tow','utility','vacuum','wood',
                   'chipper','woodchipper','x-ray','xray','zoo'
                  ]
  },
  {
        id: '0101',
        name: 'The Official Good Glue Guide to Garbage Trucks Poster!',
        source_thumb: GTPoster_thumb,
        source_alt: [GTPoster_lg,GTPoster_detail],
        source_lg: GTPoster_lg,
        info:  `Our fun guide to Garbage Trucks poster for truck lovers of all ages!
                A great way to learn the names and types of all kinds of Garbage trucks
                with colorful and fun illustrations of Garbage trucks and Sanitation
                related trucks of all kinds. Kids world wide who have enjoyed the popular
                Good Glue Truck apps now have yet another way to enjoy these fun trucks.
                Bright and colorful, a great way for your little truck enthusiast to enjoy
                and learn about Garbage trucks!`,
        link_text: 'Available now on ',
        link: 'https://www.amazon.com/dp/B0B6Y1L8BP',
        hover_image: GTPoster_hover,
        search_Terms: ['The Official Good Glue Guide to Garbage Trucks Poster!','vehicles',
                        'poster','vehicles','activity', 
                        'garbage','trash','recycling','recycle','dumpster','bin','rubbish','can',
                        'vacuum','waste','sanitation','truck','trucks','masher','bulky trash','magnet','transfer'
                      ]
      },
  {
        id: '0104',
        name: 'The Official Good Glue Guide to Garbage Trucks Placemat!',
        source_thumb: GTPlacemat_thumb,
        source_alt: [GTPlacemat_lg,GTPlacemat_detail],
        source_lg: GTPlacemat_lg,
        info:  `Our fun Guide to Garbage Trucks placemats are perfect for truck loving kiddos!
                Now kids can learn about the types of garbage collection with fun illustrations of
                garbage trucks and garbage collection related vehicles of all kinds. Bright and colorful,
                a great way for your little truck enthusiast to learn about garbage trucks!`,
        link_text: 'Available now on ',
        link: 'https://www.amazon.com/dp/B07C287T4G',
        hover_image: GTPlacemat_hover,
        search_Terms: ['The Official Good Glue Guide to Garbage Trucks Placemat!',
                       'placemats','vehicles','activity', 
                       'garbage','trash','recycling','recycle','dumpster','bin','rubbish','can',
                        'vacuum','waste','sanitation','truck','trucks','masher','bulky trash','magnet','transfer'
                      ]
      },
      {
        id: '0105',
        name: 'The Official Good Glue Classic Truck Stickers',
        source_thumb: Classic_Stickers_thumb,
        source_alt: [Classic_Stickers_lg,Classic_Stickers_detail],
        source_lg: Classic_Stickers_lg,
        info:  `Our jumbo sized 7" x 11" (18cm x 28cm) kid's sticker sheet features 11 beautiful hand 
                drawn illustrations of our classic Good Glue trucks! Great as rewards, awards, motivational 
                treats, stocking stuffers and party favors. Simply perfect for your little truck enthusiast
                and everything is more FUN with a Truck sticker on it!
        `,
        link_text: 'Available now on ',
        link: 'https://www.amazon.com/Official-Good-Glue-Classic-Stickers/dp/B0948R9VN7/ref=sr_1_1?crid=9VS2DR2AAPF9&keywords=The+Official+Good+Glue+Classic+Truck+Stickers&qid=1645913370&sprefix=the+official+good+glue+classic+truck+stickers%2Caps%2C152&sr=8-1',
        hover_image: Classic_Stickers_detail,
        search_Terms: ['Trucks! a Good Glue Notebook',
                       'vehicles','activity','book','doodle','sketch','draw','write',
                       'trucks','ambulance','bucket','cement',
                       'dump','egg','fire','garbage','trash','hoist',
                       'ice','ice cream','junk','knuckle',
                       'boom','knuckleboom','log','mail','news','ore','mining','police',
                       'quarry','recycling','recycle','street','sweeper','street sweeper',
                       'streetsweeper','tow','utility','vacuum','wood',
                       'chipper','woodchipper','x-ray','xray','zoo'
                      ]
      },
      {
        id: '0002',
        name: 'Toddler\'s A to Z Guide to Trucks Book!',
        source_thumb: AtoZBook_thumb,
        source_alt: [AtoZBook_lg,AtoZBook_detail],
        source_lg: AtoZBook_lg,
        info:  `An illustrated A to Z guide to trucks for Toddlers.
                Fun hand drawn trucks on brightly colored backgrounds
                with big high contrast upper and lower case letters for learning.
                Great for little truck enthusiasts.`,
        link_text: 'Available now on ',
        link: 'https://www.amazon.com/Official-Good-Toddlers-Guide-Trucks/dp/1543188265/ref=sr_1_3?ie=UTF8&qid=1525489127&sr=8-3&keywords=good+glue+kids+trucks',
        hover_image: AtoZBook_hover,
        search_Terms: ['Toddler\'s A to Z Guide to Trucks Book!','vehicles','books for kids',
                       'books','abc','abc\'s','alphabet','a to z','truck','trucks',
                       'ambulance','bucket','cement','dump','egg','fire',
                       'garbage','trash','hoist','ice','ice cream','junk','knuckle',
                       'boom','knuckleboom','log','mail','news','ore','mining','police',
                       'quarry','recycling','recycle','street','sweeper','street sweeper',
                       'streetsweeper','tow','utility','vacuum','wood',
                       'chipper','woodchipper','x-ray','xray','zoo'
                      ]
      },
      {
        id: '0003',
        name: 'The Official Good Glue A to Z Guide to Trucks Coloring Book!',
        source_thumb: AtoZColoringBook_thumb,
        source_alt: [AtoZColoringBook_lg,AtoZColoringBook_detail],
        source_lg: AtoZColoringBook_lg,
        info:  `This delightful 11” x 8.5” kid’s coloring book featues 26 of the fun
               Good Glue trucks from A to Z. Printed on premium white paper and single
               sided so each page can be it’s own refrigerator masterpiece. Truckloads
               of coloring fun, plus bonus “draw your own truck” pages! Great for early
               ABC learners and perfect for truck enthusiasts of all ages!`,
        link_text: 'Available now on ',
        link: 'https://www.amazon.com/Official-Good-Guide-Trucks-Coloring/dp/1544758871/ref=pd_bxgy_14_img_2?_encoding=UTF8&pd_rd_i=1544758871&pd_rd_r=E3BWNCXCHBMMFE8GTQTP&pd_rd_w=1dEIU&pd_rd_wg=Azq8j&psc=1&refRID=E3BWNCXCHBMMFE8GTQTP&dpID=51LKYOYlfYL&preST=_SX218_BO1,204,203,200_QL40_&dpSrc=detail',
        hover_image: AtoZColoringBook_hover,
        search_Terms: ['The Official Good Glue A to Z Guide to Trucks Coloring Book!',
                       'vehicles','activity','books','alphabet','travel',
                       'abc','abc\'s','a to z','truck','trucks',
                       'ambulance','bucket','cement','dump','egg','fire',
                       'garbage','trash','hoist','ice','ice cream','junk','knuckle',
                       'boom','knuckleboom','log','mail','news','ore','mining','police',
                       'quarry','recycling','recycle','street','sweeper','street sweeper',
                       'streetsweeper','tow','utility','vacuum','wood',
                       'chipper','woodchipper','x-ray','xray','zoo'
                      ]
      },
  {
    id: '0005',
    name: 'Trucks! a Good Glue Notebook',
    source_thumb: AtoZNotebook_thumb,
    source_alt: [AtoZNotebook_lg,AtoZNotebook_detail,NB_Style_Blank,NB_Style_Wide,NB_Style_College,NB_Style_Half,NB_Style_Graph],
    source_lg: AtoZNotebook_lg,
    info:  `A fun notebook with 108 pages of premium paper for writing, sketching, journaling,
            doodling, scribbling or anything else you can think of. Available in blank paper,
            college ruled paper, wide ruled paper, graph paper and half blank / half college ruled paper.`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/s?k=%22Trucks%3A+A+Good+Glue+Notebook%22&i=stripbooks&ref=nb_sb_noss',
    hover_image: AtoZNotebook_detail,
    search_Terms: ['Trucks! a Good Glue Notebook',
                   'vehicles','activity','book','doodle','sketch','draw','write',
                   'trucks','ambulance','bucket','cement',
                   'dump','egg','fire','garbage','trash','hoist',
                   'ice','ice cream','junk','knuckle',
                   'boom','knuckleboom','log','mail','news','ore','mining','police',
                   'quarry','recycling','recycle','street','sweeper','street sweeper',
                   'streetsweeper','tow','utility','vacuum','wood',
                   'chipper','woodchipper','x-ray','xray','zoo'
                  ]
  },
  {
    id: '0006',
    name: 'Green Garbage Truck T-Shirt',
    source_thumb: T_Shirt_Green_Garbage_Truck,
    source_alt: [T_Shirt_Green_Garbage_Truck,T_Shirt_Green_Garbage_Truck_hover],
    source_lg: T_Shirt_Green_Garbage_Truck,
    info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
    link_text: 'Available now at',
    link: 'https://good-glue.creator-spring.com/listing/garbage-truck-t-shirt',
    hover_image: T_Shirt_Green_Garbage_Truck_hover,
    search_Terms: ['Green Garbage Truck T-Shirt','shirts','t-shirts',
                   'tshirts','vehicles','truck','trucks','clothing','wearable',
                   'garbage','trash','recycling','recycle','toddler','green', 
                   'garbage man','rear loader','rear loading','stinky'
                  ]
  },
  {
    id: '0007',
    name: 'Fire Truck T-Shirt',
    source_thumb: T_Shirt_Fire_Truck,
    source_alt: [T_Shirt_Fire_Truck,T_Shirt_Fire_Truck_hover],
    source_lg: T_Shirt_Fire_Truck,
    info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
    link_text: 'Available now at',
    link: 'https://good-glue.creator-spring.com/listing/fire-truck-t-shirt',
    hover_image: T_Shirt_Fire_Truck_hover,
    search_Terms: ['Fire Truck T-Shirt','shirts','t-shirts', 'fire', 'firefighter', 'firefighters',
                   'fireman', 'fire man', 'tshirts', 'vehicles','truck','trucks','clothing','wearable',
                   'toddler','red', 'ladder'
                  ]
  },
  {
        id: '0008',
        name: 'Tow Truck T-Shirt',
        source_thumb: T_Shirt_Tow_Truck,
        source_alt: [T_Shirt_Tow_Truck,T_Shirt_Tow_Truck_hover],
        source_lg: T_Shirt_Tow_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/new-tow-truck-t-shirt',
        hover_image: T_Shirt_Tow_Truck_hover,
        search_Terms: ['Tow Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'blue','towing','tow','towtruck'
                      ]
      },
  {
        id: '0009',
        name: 'Dump Truck T-Shirt',
        source_thumb: T_Shirt_Dump_Truck,
        source_alt: [T_Shirt_Dump_Truck,T_Shirt_Dump_Truck_hover],
        source_lg: T_Shirt_Dump_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and sizes: 2T, 3T, 4T.`,
        link_text: 'Available now at',
        link: 'https://good-glue.creator-spring.com/listing/new-dump-truck-t-shirt',
        hover_image: T_Shirt_Dump_Truck_hover,
        search_Terms: ['Dump Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'yellow','orange','dump','dumper','dumping','construction',
                       'bulldozer','digger','dirt'
                      ]
      },
  {
        id: '0010',
        name: 'Blue Garbage Truck T-Shirt',
        source_thumb: T_Shirt_Blue_Garbage_Truck,
        source_alt: [T_Shirt_Blue_Garbage_Truck,T_Shirt_Blue_Garbage_Truck_hover],
        source_lg: T_Shirt_Blue_Garbage_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now at',
        link: 'https://good-glue.creator-spring.com/listing/new-blue-garbage-truck-t-shirt',
        hover_image: T_Shirt_Blue_Garbage_Truck_hover,
        search_Terms: ['Blue Garbage Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'garbage','trash','recycling','recycle','dumpster','front loader',
                       'front loading','blue','garbage man','stinky'
                      ]
      },
      {
        id: '0011',
        name: 'Ore Mining Truck T-Shirt',
        source_thumb: T_Shirt_Ore_Mining_Truck,
        source_alt: [T_Shirt_Ore_Mining_Truck,T_Shirt_Ore_Mining_Truck_hover],
        source_lg: T_Shirt_Ore_Mining_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/new-ore-mining-truck-t-shirt',
        hover_image: T_Shirt_Ore_Mining_Truck_hover,
        search_Terms: ['Ore Mining Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'olive','green','treads','digger','scoop','construction',
                       'mine','mining','bulldozer','digger','digging','tunnel'
                      ]
      },
      {
        id: '0012',
        name: 'Masher Truck T-Shirt',
        source_thumb: T_Shirt_Masher_Truck,
        source_alt: [T_Shirt_Masher_Truck,T_Shirt_Masher_Truck_hover],
        source_lg: T_Shirt_Masher_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/masher-truck-t-shirt',
        hover_image: T_Shirt_Masher_Truck_hover,
        search_Terms: ['Masher Truck T-Shirt','shirts','t-shirts',
                       'tshirts', 'vehicles','truck','trucks','clothing','wearable',
                       'garbage','trash','recycling','recycle','crush','squish', 'mash',
                       'orange','stinky','dump'
                      ]
      },
      {
        id: '0013',
        name: 'Street Sweeper Truck T-Shirt',
        source_thumb: T_Shirt_Street_Sweeper_Truck,
        source_alt: [T_Shirt_Street_Sweeper_Truck,T_Shirt_Street_Sweeper_Truck_hover],
        source_lg: T_Shirt_Street_Sweeper_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/street-sweeper-truck-t-shirt',
        hover_image: T_Shirt_Street_Sweeper_Truck_hover,
        search_Terms: ['Street Sweeper Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'cleaning','clean','wash','dirty','blue','street cleaner',
                       'washer'
                      ]
      },
      {
        id: '0014',
        name: 'Tow Truck MAX! T-Shirt',
        source_thumb: T_Shirt_Tow_Truck_Max,
        source_alt: [T_Shirt_Tow_Truck_Max,T_Shirt_Tow_Truck_Max_hover],
        source_lg: T_Shirt_Tow_Truck_Max,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/get-tow-truck-max-t-shirt',
        hover_image: T_Shirt_Tow_Truck_Max_hover,
        search_Terms: ['Tow Truck Max T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'black','gray','towing','tow','towtruck','monster'
                      ]
      },
      {
        id: '0015',
        name: 'Cement Truck T-Shirt',
        source_thumb: T_Shirt_Cement_Truck,
        source_alt: [T_Shirt_Cement_Truck,T_Shirt_Cement_Truck_hover],
        source_lg: T_Shirt_Cement_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/get-cement-truck-t-shirt',
        hover_image: T_Shirt_Cement_Truck_hover,
        search_Terms: ['Cement Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'blue green','orange','hot pink','construction','mixer','concrete',
                       'bulldozer'
                      ]
      },
      {
        id: '0016',
        name: 'Recycling Truck T-Shirt',
        source_thumb: T_Shirt_Recycling_Truck,
        source_alt: [T_Shirt_Recycling_Truck,T_Shirt_Recycling_Truck_hover],
        source_lg: T_Shirt_Recycling_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/new-recycling-truck-t-shirt',
        hover_image: T_Shirt_Recycling_Truck_hover,
        search_Terms: ['Recycling Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'garbage','trash','recycling','recycle','stinky','blue','earth day', 
                       'environment','climate','garbage man'
                      ]
      },
      {
        id: '0017',
        name: 'Ice Cream Truck T-Shirt',
        source_thumb: T_Shirt_Ice_Cream_Truck,
        source_alt: [T_Shirt_Ice_Cream_Truck,T_Shirt_Ice_Cream_Truck_hover],
        source_lg: T_Shirt_Ice_Cream_Truck,
        info:  `An Official Good Glue T-Shirt featuring an awesome hand drawn illustration!
                Available in multiple colors and toddler sizes: 2T, 3T, 4T.`,
        link_text: 'Available now on',
        link: 'https://good-glue.creator-spring.com/listing/new-ice-cream-truck-t-shirt',
        hover_image: T_Shirt_Ice_Cream_Truck_hover,
        search_Terms: ['Ice Cream Truck T-Shirt','shirts','t-shirts',
                       'tshirts','vehicles','truck','trucks','clothing','wearable',
                       'vanilla','chocolate','strawberry','kids','ice cream man','popsicles',
                       'pops','treat','flavors','colorful'
                      ]
      },
  {
    id: '0018',
    name: 'Pops! a Good Glue Notebook',
    source_thumb: PopsNotebook_thumb,
    source_alt: [PopsNotebook_lg,PopsNotebook_detail,NB_Style_Blank,NB_Style_Wide,NB_Style_College,NB_Style_Half,NB_Style_Graph],
    source_lg: AtoZNotebook_lg,
    info:  `A fun notebook with 108 pages of premium paper for writing, sketching, journaling,
            doodling, scribbling or anything else you can think of. Available in blank paper,
            college ruled paper, wide ruled paper, graph paper and half blank / half college ruled paper.`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/s?k=%22Pops%3A+A+Good+Glue+Notebook%22&i=stripbooks&ref=nb_sb_noss',
    hover_image: PopsNotebook_detail,
    search_Terms: ['Pops! a Good Glue Notebook',
                   'Doodle','Sketch','Notes','Write','Writing','Drawing','Popsicles',
                   'treat','dessert','activity'
                  ]
    
  },
  {
    id: '0019',
    name: 'Junk! a Good Glue Notebook',
    source_thumb: JunkNotebook_thumb,
    source_alt: [JunkNotebook_lg,JunkNotebook_detail,NB_Style_Blank,NB_Style_Wide,NB_Style_College,NB_Style_Half,NB_Style_Graph],
    source_lg: AtoZNotebook_lg,
    info:  `A fun notebook with 108 pages of premium paper for writing, sketching, journaling,
            doodling, scribbling or anything else you can think of. Available in blank paper,
            college ruled paper, wide ruled paper, graph paper and half blank / half college ruled paper.`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/s?k=%22Junk%3A+A+Good+Glue+Notebook%22&i=stripbooks&ref=nb_sb_noss',
    hover_image: JunkNotebook_detail,
    search_Terms: ['Junk! a Good Glue Notebook',
                   'Doodle','Sketch','Notes','Write','Writing','Drawing',
                   'garbage','trash','collector','activity','junkyard','throw away'
                  ]
  },
  {
    id: '0020',
    name: 'Bugs! a Good Glue Notebook',
    source_thumb: BugsNotebook_thumb,
    source_alt: [BugsNotebook_lg,BugsNotebook_detail,NB_Style_Blank,NB_Style_Wide,NB_Style_College,NB_Style_Half,NB_Style_Graph],
    source_lg: BugsNotebook_lg,
    info:  `A fun notebook with 108 pages of premium paper for writing, sketching, journaling,
            doodling, scribbling or anything else you can think of. Available in blank paper,
            college ruled paper, wide ruled paper, graph paper and half blank / half college ruled paper.`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/s?k=%22Bugs%3A+A+Good+Glue+Notebook%22&i=stripbooks&ref=nb_sb_noss',
    hover_image: BugsNotebook_detail,
    search_Terms: ['Bugs! a Good Glue Notebook',
                   'Doodle','Sketch','Notes','Write','Writing','Drawing',
                   'spider','pillbug','fly','centipede','earthworm',
                   'butterfly','lady bug','ladybug','ant','beetle','scorpion',
                   'bee','wasp','insects','activity'
                  ]
  },
  {
    id: '0021',
    name: 'Donuts! a Good Glue Notebook',
    source_thumb: DonutsNotebook_thumb,
    source_alt: [DonutsNotebook_lg,DonutsNotebook_detail,NB_Style_Blank,NB_Style_Wide,NB_Style_College,NB_Style_Half,NB_Style_Graph],
    source_lg: DonutsNotebook_lg,
    info:  `A fun notebook with 108 pages of premium paper for writing, sketching, journaling,
            doodling, scribbling or anything else you can think of. Available in blank paper,
            college ruled paper, wide ruled paper, graph paper and half blank / half college ruled paper.`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/s?k=%22Donuts%3A+A+Good+Glue+Notebook%22&i=stripbooks&ref=nb_sb_noss',
    hover_image: DonutsNotebook_detail,
    search_Terms: ['Donuts! a Good Glue Notebook',
                   'Doodle','Sketch','Notes','Write','Writing','Drawing',
                   'treat','dessert','breakfast','delicious','activity',
                   'chocolate','vanilla','glazed','plain','frosted','strawberry',
                   'sweet','coffee','homer'
                  ]
  },
  {
    id: '0022',
    name: 'Computers! a Good Glue Notebook',
    source_thumb: ComputersNotebook_thumb,
    source_alt: [ComputersNotebook_lg,ComputersNotebook_detail,NB_Style_Blank,NB_Style_Wide,NB_Style_College,NB_Style_Half,NB_Style_Graph],
    source_lg: ComputersNotebook_lg,
    info:  `A fun notebook with 108 pages of premium paper for writing, sketching, journaling,
            doodling, scribbling or anything else you can think of. Available in blank paper,
            college ruled paper, wide ruled paper, graph paper and half blank / half college ruled paper.`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/s?k=%22Computers%3A+A+Good+Glue+Notebook%22&i=stripbooks&ref=nb_sb_noss',
    hover_image: ComputersNotebook_detail,
    search_Terms: ['Computers! a Good Glue Notebook',
                   'Doodle','Sketch','Notes','Write','Writing','Drawing',
                   'electronics','retro','mac','pc','windows','calculator','activity',
                   'nerd','developer','apple','microsoft','mathematics','algebra'
                  ]
  },
  {
    id: '0023',
    name: 'Cones! a Good Glue Notebook',
    source_thumb: ConesNotebook_thumb,
    source_alt: [ConesNotebook_lg,ConesNotebook_detail,NB_Style_Blank,NB_Style_Wide,NB_Style_College,NB_Style_Half,NB_Style_Graph],
    source_lg: ConesNotebook_lg,
    info:  `A fun notebook with 108 pages of premium paper for writing, sketching, journaling,
            doodling, scribbling or anything else you can think of. Available in blank paper,
            college ruled paper, wide ruled paper, graph paper and half blank / half college ruled paper.`,
    link_text: 'Available now on ',
    link: 'https://www.amazon.com/s?k=%22Cones%3A+A+Good+Glue+Notebook%22&i=stripbooks&ref=nb_sb_noss',
    hover_image: ConesNotebook_detail,
    search_Terms: ['Cones! a Good Glue Notebook',
                   'Doodle','Sketch','Notes','Write','Writing','Drawing',
                   'treat','dessert','ice cream','delicious','chocolate',
                   'vanilla','flavors','scoops','strawberry','activity',
                   'mint','scoops','hot fudge','fudge','sweet'
                  ]
  }
   
   
]

export default ProductData